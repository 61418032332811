
import React, { useState, useEffect } from 'react';

const SuccessPage = () => {

    return (
        <>
            <div className='centerDiv'>
                <p>Thank you! Your selection has been successfully saved.</p>
            </div>
        </>
    );
};
export default SuccessPage;

