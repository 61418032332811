import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { isEmpty } from "../lib/util";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

const HolidayPartyPage = () => {
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_SERVER_URL;

  // Simplified state management for form data
  const [formData, setFormData] = useState({
    employeeName: "",
    isAttending: "",
    mealSelection: "", // New field for meal choice
    foodAllergies: "", // New field for allergies
  });

  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(formData.employeeName) || isEmpty(formData.isAttending)) {
      toast.error("Please fill in all required fields");
      return;
    }

    // Additional validation for attending guests
    if (formData.isAttending === "yes" && isEmpty(formData.mealSelection)) {
      toast.error("Please select your meal preference");
      return;
    }

    /*
      {
        "isAttending": "string",
        "employeeName": "string",
        "mealSelection": "string",
        "allergy": "string",
        "guests": [
          "string"
        ]
      }
    */
    try {
      const payload = {
        employeeName: formData.employeeName,
        isAttending: formData.isAttending,
        mealSelection: formData.mealSelection,
        allergy: formData.foodAllergies,
      };
      
      await axios.post(`${api_url}rsvp`, payload);
      toast.success("RSVP saved successfully.");
      navigate("/success");
    } catch (error) {
      console.error("Error saving the RSVP", error);
      toast.error("Error saving the RSVP.");
    }
  };

  // Render RSVP form
  return (
    <Container className="rsvp-container">
      {/* Event banner image */}
      <Row>
        <Col>
          <img
            src={require("../images/Holiday 2024.png")}
            alt="Holiday Party 2024"
            className="event-image"
          />
        </Col>
      </Row>

      <Form onSubmit={handleSubmit}>
        {/* Employee name input */}
        <Row>
          <Col>
            <FormGroup>
              <Input
                type="text"
                className= "employeeNameText"
                name="employeeName"
                value={formData.employeeName}
                onChange={handleInputChange}
                placeholder="Please enter your first and last name"
                required
              />
            </FormGroup>
          </Col>
        </Row>

        {/* Attendance selection */}
        <Row>
          <Col>
            <FormGroup tag="fieldset">
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="isAttending"
                    value="yes"
                    checked={formData.isAttending === "yes"}
                    onChange={handleInputChange}
                  />{" "}
                  Attending
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="isAttending"
                    value="no"
                    checked={formData.isAttending === "no"}
                    onChange={handleInputChange}
                  />{" "}
                  Not Attending
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Meal Selection and Allergies (only shown if attending) */}
        {formData.isAttending === "yes" && (
          <>
            {/* Meal Selection */}
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <Label>Meal Selection *</Label>
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="mealSelection"
                          value="duo"
                          checked={formData.mealSelection === "duo"}
                          onChange={handleInputChange}
                        />{" "}
                        Duo Entrée
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="mealSelection"
                          value="vegan"
                          checked={formData.mealSelection === "vegan"}
                          onChange={handleInputChange}
                        />{" "}
                        Vegan
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* Food Allergies */}
            <Row className="mt-3">
              <Col>
                <FormGroup>
                  <Label>Food Allergies/Dietary Restrictions</Label>
                  <Input
                    type="textarea"
                    name="foodAllergies"
                    value={formData.foodAllergies}
                    onChange={handleInputChange}
                    placeholder="Please list any food allergies or dietary restrictions"
                    rows="3"
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {/* Submit button */}
        <Row className="mt-4">
          <Col>
            <Button
              type="submit"
              color="primary"
              className="submit-rsvp-button"
            >
              Submit RSVP
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default HolidayPartyPage;
